import React, { useState, useRef, useEffect } from "react";
import { navigate } from "gatsby";
import { LoadContent, API_URL, ORDERS_BY_ORDER_ID } from "@common";
import { useFetch } from "@hooks";
import { Section, OrderDetails, Layout, OrderFind } from "@components";
import { FETCH_STATUS } from "@hooks";
import * as styles from "./orders.module.css";

const STRAPI_ORDERS = `${API_URL}${ORDERS_BY_ORDER_ID}`;

const OrdersPage = ({ location }) => {
  const queryParams = new URLSearchParams(location.search);
  const initialOrderNo = queryParams.get("number");

  const [orderId, setOrderId] = useState(initialOrderNo);

  const pageReferences = {
    TRACKING: useRef(),
    CONTACT: useRef(),
  };

  const scrollToNode = (node) => {
    node.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollFunctions = {
    scrollToTracking: () => scrollToNode(pageReferences.TRACKING),
    scrollToContact: () => scrollToNode(pageReferences.CONTACT),
  };

  const { scrollToTracking, scrollToContact } = scrollFunctions;

  useEffect(() => {
    scrollToTracking();
  }, []);

  const navigationOptions = [
    { sectionStringCode: "main-page", scrollFunction: () => navigate("/") },
    { sectionStringCode: "tracking", scrollFunction: scrollToTracking },
    { sectionStringCode: "contact", scrollFunction: scrollToContact },
  ];

  const handleBack = () => {
    navigate("/");
  };

  const { status, data } = useFetch(`${STRAPI_ORDERS}${orderId}`);

  return (
    <>
      <Layout
        navigationOptions={navigationOptions}
        pageReferences={pageReferences}
      >
        <OrderFind
          parentRef={pageReferences.TRACKING}
          initialOrderValue={orderId}
          onClick={setOrderId}
        />
        <Section>
          <div className={`${styles.detailsContainer} ${styles.centerAlign}`}>
            <LoadContent isLoading={status === FETCH_STATUS.FETCHING}>
              <OrderDetails order={data} />
            </LoadContent>
          </div>
          <div onClick={handleBack} className={styles.backLink}>
            Kliknij tutaj aby powrócić do strony głównej
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default OrdersPage;
